var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"elevation":"1"}},[(_vm.isDialog)?_c('v-toolbar-title',[_vm._v(_vm._s(_vm.name))]):_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v(" "+_vm._s(_vm.termText)+" - "+_vm._s(_vm.termAssignment.assignment)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fal fa-chevron-down")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.assignments),function(ref){
var t = ref.term;
var termText = ref.termText;
var assignment = ref.assignment;
return _c('v-list-item',{key:t,on:{"click":function($event){_vm.term = t}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(termText))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(assignment))])],1)],1)}),1)],1),_c('v-spacer'),(_vm.isDialog)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v(" "+_vm._s(_vm.termText)+" - "+_vm._s(_vm.termAssignment.assignment)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fal fa-chevron-down")])],1)]}}],null,false,499366335)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.assignments),function(ref){
var t = ref.term;
var termText = ref.termText;
var assignment = ref.assignment;
return _c('v-list-item',{key:t,on:{"click":function($event){_vm.term = t}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(termText))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(assignment))])],1)],1)}),1)],1):_vm._e(),(_vm.isDialog)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("fal fa-times")])],1):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.termAssignment.history,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.stringFormatDate(item.date)))]}}])})],1),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }