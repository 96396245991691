<template>
  <v-card>
    <v-toolbar elevation="1">
      <v-toolbar-title v-if="isDialog">{{ name }}</v-toolbar-title>
      <v-menu v-else>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text>
            {{ termText }} - {{ termAssignment.assignment }}
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-for="{ term: t, termText, assignment } of assignments" :key="t" @click="term = t">
            <v-list-item-content>
              <v-list-item-title>{{ termText }}</v-list-item-title>
              <v-list-item-subtitle>{{ assignment }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer />
      <v-menu v-if="isDialog">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text>
            {{ termText }} - {{ termAssignment.assignment }}
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-for="{ term: t, termText, assignment } of assignments" :key="t" @click="term = t">
            <v-list-item-content>
              <v-list-item-title>{{ termText }}</v-list-item-title>
              <v-list-item-subtitle>{{ assignment }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn v-if="isDialog" icon @click="$emit('close')">
        <v-icon>fal fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-data-table :items="termAssignment.history" :headers="headers">
        <template v-slot:item.date="{ item }">{{ stringFormatDate(item.date) }}</template>
      </v-data-table>
    </v-card-text>
    <!-- <v-expansion-panels>
      <v-expansion-panel v-for="{ term, termText, assignment, hours, history } of assignments" :key="term">
        <v-expansion-panel-header>
          <v-row>
            <v-col>{{ termText }}</v-col>
            <v-col>Assigned Area: {{ assignment }}</v-col>
            <v-col>Hours Served: {{ hours || 0 }}</v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list>
            <v-list-item v-for="{ _id, date, hours, desc, addedBy } of history" :key="_id">
              <v-list-item-content v-if="desc">
                <v-list-item-title>{{ desc }}</v-list-item-title>
                <v-list-item-subtitle>{{ stringFormatDate(date, true, true) }}</v-list-item-subtitle>
                <v-list-item-subtitle>Added by: {{ addedBy }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content v-else>
                <v-list-item-title>{{ stringFormatDate(date, true, true) }}</v-list-item-title>
                <v-list-item-subtitle>Added by: {{ addedBy }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>{{ hours }} Hrs</v-list-item-action>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels> -->
    <slot></slot>
  </v-card>
</template>
<script>
import { computed, ref, watch } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    isDialog: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { root }) {
    watch(() => props.id, () => {
      console.log('props.id changed')
      loadData()
    })

    const name = ref('')
    const assignments = ref({})
    function loadData () {
      console.log('Loading data')
      root.$feathers.service('serve/student').get(props.id).then((data) => {
        name.value = data.first + ' ' + data.last
        assignments.value = data.assignments.reverse().map((row) => {
          const year = row.term.substring(0, 4)
          row.termText = (row.term.substring(4, 6) === '05' ? 'Spring ' : 'Fall ') + year
          return row
        })
        if (assignments.value.length > 0) term.value = assignments.value[0].term
      })
    }

    const term = ref('')
    const termText = computed(() => {
      const rec = assignments.value.find(({ term: t }) => t === term.value)
      if (rec) {
        return rec.termText
      }
      return ''
    })
    const termAssignment = computed(() => {
      const rec = assignments.value.find(({ term: t }) => t === term.value)
      if (rec) return rec
      return {
        assignment: '',
        hours: 0,
        history: []
      }
    })

    const headers = ref([
      { text: 'Entered Date', value: 'date' },
      { text: 'Description', value: 'desc' },
      { text: 'Entered By', value: 'addedBy' },
      { text: 'Hours Served', value: 'hours' }
    ])

    return {
      name,
      assignments,
      loadData,
      term,
      termText,
      termAssignment,
      headers,
      stringFormatDate
    }
  }
}
</script>
